<template>
  <!-- <div id="app"> -->
  <component :is="layout">
    <router-view />
  </component>
  <!-- </div> -->
</template>

<style lang="css">
@import "~materialize-css/dist/css/materialize.min.css";
/* import Leaflet scc */
@import "~leaflet/dist/leaflet.css";


@import "assets/index.css";
</style>

<script>
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>