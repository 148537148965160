<script>
import logger from "@/utils/logger.plugin";
import { formatDatetime } from "@/utils/date.filter";
import { computed } from "vue";

export default {
  name: "LogPanel",
  props: {
    snOpen: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const full = computed(() => {
      return !props.snOpen;
    });
    const logs = logger.getLogs();
    return { full, logs, formatDatetime };
  },
};
</script>

<template>
  <div>
    <div id="log-panel" v-if="logs.length" :class="{ full: full }">
        <div v-for="log in logs" :key="log.id" :class="['log', log.type]">
          {{ formatDatetime(log.date) }} - {{ log.message }}
        </div>
    </div>
  </div>
</template>

<style scoped>
#log-panel {
  position: fixed;
  left: 0;
  padding-left: 243px;
  bottom: 0;
  width: 100%;
  height: 28px;
  background-color: #fff;
  z-index: 99;
}

#log-panel:hover {
  height: auto;
  max-height: 160px;
  overflow-y: scroll;
}

#log-panel.full {
  padding-left: 42px;
}

.log {
  padding: 2px 4px;
  border-top: 1px solid #e6e6e6;
  font-size: 80%;
  color: #333;
  min-height: 28px;
  word-break: break-all;
}

.log.success {
  color: #325e40;
}

.log.error {
  color: #9c1414;
}

.log.info {
  color: #1e6bb8;
}
</style>
