<template>
  <div>
    <!-- <button class="toastTest" @click="testToast">Test toast</button> -->
    <div class="toastContainer">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        :class="['toast', toast.type]"
      >
        {{ toast.message }}
      </div>
    </div>
  </div>
</template>

<script>
import useToast from "@/hooks/useToast";

export default {
  setup() {
    const toasts = useToast.getToasts();
    let cnt = 0;

    const testToast = () => {
      useToast.addSuccess("Действие выполнено успешно! - " + cnt++);
      if (Math.random() > 0.5) {
        useToast.addError("Ошибка выполнения действия! - " + cnt++);
      }
    };

    return {
      toasts,
      testToast,
    };
  },
};
</script>

<style scoped>
button.toastTest {
  position: fixed;
  left: 8px;
  top: 8px;
  z-index: 100;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #333;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  /* transition: opacity 0.3s ease; */
  /* opacity: 0.85; */
}

.toastContainer {
  position: fixed;
  min-width: 20%;
  top: 0px;
  right: 20px;
  z-index: 10000;
}

.toast {
  /* position: relative; */
  background-color: #333;
  color: white;
  font-size: 90%;
  font-weight: 400;
  padding: 8px 16px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  margin-bottom: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
}

.toast.success {
  background-color: #4aa155;
  color: #e8e9e9;
}

.toast.error {
  /* background-color: #c74244; */
  background-color: rgb(150, 122, 0);
  color: #e8e9e9;
}
</style>