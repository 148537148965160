import { ref, computed } from 'vue';

const toasts = ref([]);
const idCounter = ref(0);

const toast = {
    add(type, message, duration = 5000) {
        const id = idCounter.value++;
        const toast = { id, type, message, duration };

       // toasts.value.push(toast);
        toasts.value = [toast, ...toasts.value];

        const removeToast = () => {
            const index = toasts.value.findIndex((t) => t.id === id);
            if (index !== -1) {
                toasts.value.splice(index, 1);
            }
        };

        setTimeout(removeToast, duration);
    },

    addError(message, duration) {
        this.add('error', message, duration);
    },

    addSuccess(message, duration) {
        this.add('success', message, duration);
    },

    getToasts() {   
        return computed(() => toasts.value);
    }
}

export default toast;

// export default function useToast() {
//     const addToast = (type, message, duration = 3000) => {
//         const id = idCounter.value++;
//         const toast = { id, type, message, duration };

//         toasts.value.push(toast);

//         const removeToast = () => {
//             const index = toasts.value.findIndex((t) => t.id === id);
//             if (index !== -1) {
//                 toasts.value.splice(index, 1);
//             }
//         };

//         setTimeout(removeToast, duration);
//     };

//     const addErrorToast = (message, duration) => addToast('error', message, duration);
//     const addSuccessToast = (message, duration) => addToast('success', message, duration);

//     return {
//         toasts: computed(() => toasts.value),
//         addErrorToast, addSuccessToast,
//         addToast,
//     };
// }
