<template>
  <div class="empty-layout">
    <ToastMsg />
    <router-view />
  </div>
</template>

<script>
import ToastMsg from "@/components/app/ToastMsg.vue";

export default {
  setup() {
    return {};
  },

  components: {
    ToastMsg,
  },
};
</script>

<style scoped>
.empty-layout {
  background-image: linear-gradient(120deg, #B85541, #158BB9);
}

</style>