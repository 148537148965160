import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import toastPlugin from '@/utils/toast.plugin'

createApp(App)
    .use(router)
    .use(toastPlugin)
    .mount('#app')
