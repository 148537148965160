<script setup>
import { ref, reactive, onMounted } from "vue";
import categories from "@/hooks/useCats";

const isOpen = ref(false)
const cats = reactive(categories.getCategories());
// const activeCat = reactive(categories.getActiveCat());

const toggleNavHandler = () => {
    isOpen.value = !isOpen.value;
};

const clk = (name) => {
    isOpen.value = !isOpen.value;
    document.title = name;
}

onMounted(() => {

})

</script>

<template>
    <div id="side-nav" v-if="isOpen">
        <div class="container">
            <div class="content">
                <div class="list" v-for='(cat, idx) in cats' :key="idx">
                    <div class="header">{{ cat.name }}</div>
                    <div class="items">
                        <router-link v-for="item in cat.links" :key="item.id" :to="item.link" @click="clk(item.name)"
                            class="router-link-custom">
                            {{ item.name }}
                        </router-link>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>
    </div>
    <div class="side-nav-bg" v-if="isOpen"></div>
    <span class="nav-trg-btn" v-if="isOpen" @click="toggleNavHandler">X</span>
    <span class="nav-trg-btn" v-if="!isOpen" @click="toggleNavHandler">+</span>
</template>

<style scoped>

#side-nav {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 9000;
}

.nav-trg-btn {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10000;
    padding: 8px 16px;
    background-color: #333;
    color: #eee;
    font-weight: bold;
    z-index: 9999;
}

.nav-trg-btn:hover {
    background-color: #926969;
    cursor: pointer;
}

.side-nav-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #222; /*B85541*/
    z-index: 8888;
}

.list {
    width: 100%;
}

.header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 32px;
    margin-top: 32px;
    text-align: left;
    color: #bbb;
}

.items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
}

.router-link-custom {
    text-decoration: none;
    color: #ddd;
    background-color: #634c4c;
    border-radius: 3px;
    padding: 16px;
    text-align: center;
    transition: background-color 0.3s ease;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.router-link-custom:hover {
    background-color: #473939;
}

.router-link-exact-active {
    background-color: #862b16;
    color: #fff;
}

</style>