import { ref, computed } from 'vue';

const logs = ref([]);
const idCounter = ref(0);

const logger = {
    add(type, message) {
        const id = idCounter.value++;
        const toast = { id, type, message, date: new Date() };

        // add log in reverse order
        logs.value.unshift(toast);

        // leave last 50 logs and delete the rest
        if (logs.value.length > 50) {
            logs.value.splice(50, logs.value.length);
        }
    },

    addError(message) {
        this.add('error', message);
    },

    addSuccess(message) {
        this.add('success', message);
    },

    addInfo(message) {
        this.add('info', message);
    },

    getLogs() {   
        return computed(() => logs.value);
    }
}

export default logger;
