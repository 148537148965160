<script>
import LogPanel from "@/components/app/LogPanel.vue";
import ToastMsg from "@/components/app/ToastMsg.vue";
import SideNav from "@/components/app/FullNav.vue";
import { ref } from "vue";

export default {
  name: "main-layout",

  setup() {
    const loading = ref(false);
    return {
      loading,
    };
  },

  components: {
    ToastMsg,
    SideNav,
    LogPanel,
  },
};
</script>

<template>
  <div class="app-main-layout" id="page">
    <ToastMsg />
    <SideNav />
    <LogPanel />
    <main id="content">
      <router-view />
    </main>
  </div>
</template>

<style scoped>
#page {
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
}

#content {
  display: flex;
  flex-direction: column;

  min-height: 100%;
  width: 100%;

  padding: 0px 0px 16px 0px;
  background-color: #f5f5f5;
  font-size: 95%;
}
</style>