import M from 'materialize-css'

export default {
    install(Vue) {
        Vue.config.globalProperties.$toast = function (html) {
            M.toast({ html })
        }

        Vue.config.globalProperties.$toastError = function (html) {
            M.toast({ html: `[Ошибка]: ${html}` })
        }

        Vue.config.globalProperties.$toastSuccess = function (html) {
            M.toast({ html: `${html}`, classes: 'green'})
        }
    }
}