// useAuth.js
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import logger from '@/utils/logger.plugin';

const auth = {
    accessToken: null, // Токен доступа
    refreshToken: null, // Токен обновления
    currentUser: null, // Текущий пользователь
    userData: {}, // Данные пользователя
    baseUrl: `${process.env.VUE_APP_URL_GATEWAY}/account`,
    hasPermissions: (...permissions) => {
        if (!auth.userData || !auth.userData.permissions) {
            return false;
        }

        for (let i = 0; i < permissions.length; i++) {
            if (!auth.userData.permissions.includes(permissions[i])) {
                return false;
            }
        }

        return true;
    },

    // Авторизация по логину и паролю
    login: async (username, password, onError) => {
        // Очищаем токены и текущего пользователя в localStorage
        auth.clearAll();
        try {
            const response = await axios.post(`${auth.baseUrl}/v1/auth/login`, {
                username,
                password,
            });

            // Если в ответе есть токен доступа, обновляем его
            if (response.data && response.data.data) {
                const { access_token, refresh_token } = response.data.data;

                // Сохраняем токены в localStorage
                auth.setAccessToken(access_token);
                auth.setRefreshToken(refresh_token);
            }
        } catch (error) {
            if (error.response && error.response.data.error) {
                const err = error.response.data.error
                if (err.code == "PHC010") {
                    onError("identity service error");
                    return;
                }

                onError(error.response.data.error.message);
                return;
            }

            onError("logIn failed");
            console.error('LogIn error:', error);
        }

        logger.addSuccess('logged in');

        return auth.getCurrentUser();
    },

    // Обновление токена доступа по токену обновления
    refreshAccessToken: async (onError) => {

        // Если токен обновления не задан, выходим
        if (!auth.getRefreshToken()) {
            auth.clearAll();
            onError("refresh token is not set");
            logger.addError('refresh token is not set');
            return false;
        }

        try {
            const response = await axios.post(`${auth.baseUrl}/v1/auth/refresh`, {
                token: auth.getRefreshToken(),
            });

            // Если в ответе есть токен доступа, обновляем его
            if (response.data && response.data.data.access_token) {
                auth.setAccessToken(response.data.data.access_token);
            }
        } catch (error) {
            if (error.response && error.response.data.error) {
                const err = error.response.data.error
                if (err.code == "PHC010") {
                    onError("identity service error");
                    return false;
                }

                onError(err.message);
                return false;
            }

            onError("refresh failed");
            logger.addError('refresh error: ' + error.message);
            return false;
        }

        logger.addSuccess('access token refreshed');
        return true;
    },

    // Выход
    logout: () => {
        try {
            // Посылаем запрос на выход с токеном обновления
            axios.post(`${auth.baseUrl}/v1/auth/logout`, {
                token: auth.getRefreshToken(),
            });
        } catch (error) {
            logger.addError(error);
            console.error('LogOut error:', error);
        }

        // Очищаем токены и текущего пользователя в localStorage
        auth.clearAll();
    },

    // Отправка ссылки для восстановления пароля
    sendPasswdLink: async (p) => {
        try {
            await axios.post(`${auth.baseUrl}/v1/passwd-link/new`, {
                "username" : p.username,
                "email": p.email,
            });
        } catch (error) {
            if (error.response && error.response.data.error) {
                const err = error.response.data.error
                if (err.code == "PHC010") {
                    p.onError("identity service error");
                    return;
                }

                p.onError(err.message);
                return;
            }

            p.onError("send password recovery link failed");
            console.error('sendPasswordRecoveryLink error:', error);
        }

        p.onSuccess();
    },

    // Использование ссылки для восстановления пароля
    usePasswdLink: async (p) => {
        try {
            await axios.post(`${auth.baseUrl}/v1/passwd-link/use`, {
                "username" : p.username,
                "link": p.token,
                "password": p.password,
            });
        } catch (error) {
            if (error.response && error.response.data.error) {
                const err = error.response.data.error
                if (err.code == "PHC010") {
                    p.onError("identity service error");
                    return;
                }

                p.onError(err.message);
                return;
            }

            p.onError("use password recovery link failed");
            console.error('usePasswordRecoveryLink error:', error);
        }

        p.onSuccess();
    },

    // Получение экземпляра axios с заголовком авторизации
    getAxiosInstance: (baseUrl) => {
        const axiosInstance = axios.create({
            baseURL: baseUrl,
        });

        // Добавляем заголовок авторизации для всех запросов
        axiosInstance.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${auth.getAccessToken()}`;
            return config;
        });

        // Добавляем перехватчик ошибок для всех запросов
        axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                // Если запрос не получил ответа, возвращаем ошибку как есть
                if (!error.response || !error.response.status) {
                    return Promise.reject(error);
                }

                // Проверяем, что ошибка 401 Unauthorized и это не повторная попытка
                if (error.response.status === 401 && !originalRequest._retry) {
                    // Если это первая попытка повторить запрос
                    originalRequest._retry = true;

                    try {
                        // Пытаемся обновить токен
                        logger.addInfo('refreshing access token');
                        await auth.refreshAccessToken((err) => {
                            logger.addError(`refreshing access token failed: ${err}`);
                        });
                    } catch (e) {
                        // Если обновление токена не удалось, выходим из приложения
                        const newError = new Error('Unauthorized');
                        return Promise.reject(newError);
                    }


                    // После обновления токена повторяем оригинальный запрос с новым токеном
                    originalRequest.headers['Authorization'] = `Bearer ${auth.getAccessToken()}`;
                    return axiosInstance(originalRequest);
                }

                // Если ошибка не 401 или повторная попытка, возвращаем ошибку как есть
                return Promise.reject(error);
            }
        );

        return axiosInstance;
    },

    getCurrentUser: () => {
        if (auth.currentUser) {
            return auth.currentUser;
        }

        const user = localStorage.getItem('currentUser');
        if (user) {
            auth.currentUser = JSON.parse(user);
            auth.userData = auth.currentUser.payload.user;
            // if exipred
            if (auth.currentUser.exp < Date.now() / 1000) {
                logger.addInfo('token expired. logout');
                auth.clearAll();
                return null;
            }
            
            return auth.currentUser;
        }

        return null;
    },
    getAccessToken: () => {
        if (auth.accessToken) {
            return auth.accessToken;
        }

        const token = localStorage.getItem('accessToken');
        if (token) {
            auth.accessToken = token;
            return auth.accessToken;
        }

        return '';
    },
    getRefreshToken: () => {
        if (auth.refreshToken) {
            return auth.refreshToken;
        }

        const token = localStorage.getItem('refreshToken');
        if (token) {
            auth.refreshToken = token;
            return auth.refreshToken;
        }

        return '';
    },

    setAccessToken: (token) => {
        auth.accessToken = token;
        localStorage.setItem('accessToken', token);
        // Сохраняем текущего пользователя в localStorage
        auth.setCurrentUser(jwtDecode(token));
    },
    setRefreshToken: (token) => {
        auth.refreshToken = token;
        localStorage.setItem('refreshToken', token);
    },
    setCurrentUser: (user) => {
        auth.currentUser = user;
        localStorage.setItem('currentUser', JSON.stringify(user));
    },
    clearAll: () => {
        auth.accessToken = '';
        auth.refreshToken = '';
        auth.currentUser = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('currentUser');
    }
};

export default auth;