import { ref } from "vue";
import useAuth from "@/hooks/useAuth";
let _idCounter = 10;

const categories = {
    cats: ref([]),
    _cats: ref([
        {
            id: _idCounter++, order: 1, type: "middle", name: "Access Control", icon: "accessible",
            links: [
                { id: _idCounter++, order: 0, name: "Account", link: "/acl/account", view: "acl/AccountView", perms: ["readUser", "updateUser"] },
                { id: _idCounter++, order: 2, name: "Accounts", link: "/acl/accounts", view: "acl/AccountListView", perms: ["readUser", "updateUser"] },
                { id: _idCounter++, order: 3, name: "Roles", link: "/acl/rpms", view: "acl/RolePermissionsView", perms: ["updateRolePermission"] },
                { id: _idCounter++, order: 4, name: "Permissions", link: "/acl/pms", view: "acl/PermissionsView", perms: ["updateRolePermission"] },
                { id: _idCounter++, order: 5, name: "Add user", link: "/acl/newaccount", view: "acl/NewAccountView", perms: ["updateUser"] },
                { id: _idCounter++, order: 6, name: "Log List", link: "/logs/list", view: "tools/LogListView", perms: ["log-get"] },
                { id: _idCounter++, order: 7, name: "Log rules", link: "/logs/rules", view: "tools/LogRulesView", perms: ["log-rule-read"] },
            ]
        },
        {
            id: _idCounter++, order: 2, type: "middle", name: "Testing phase", icon: "lightbulb_outline",
            links: [
                { id: _idCounter++, order: 1, name: "Main", link: "/", view: "HomeView" },
                { id: _idCounter++, order: 2, name: "LogTest", link: "/LogTestView", view: "test/LogTestView", perms: ["beta"] },
                { id: _idCounter++, order: 3, name: "Order data", link: "/orders/", view: "orders/OrderDetailsView", perms: ["orderDataView"] },
                { id: _idCounter++, order: 4, name: "LayoutTest", link: "/LayoutTestView", view: "test/LayoutColumnsView", perms: ["beta"] },
                { id: _idCounter++, order: 5, name: "ModalTest", link: "/ModalTestView", view: "test/ModalTestView", perms: ["updateUser"] },
                { id: _idCounter++, order: 6, name: "MapTest", link: "/MapTest", view: "test/MapTest", perms: ["updateUser"] },
                { id: _idCounter++, order: 7, name: "GMapTest", link: "/GMapTest", view: "test/GMapTest", perms: ["updateUser"] },
                { id: _idCounter++, order: 8, name: "MapTest2", link: "/MapTest2", view: "test/MapTest2", perms: ["updateUser"] },
                { id: _idCounter++, order: 9, name: "MapTest3", link: "/MapTest3", view: "test/MapTest3", perms: ["updateUser"] },
                { id: _idCounter++, order: 10, name: "Address", link: "/address", view: "tools/AddressView", perms: ["address-bounded-list"] },
                { id: _idCounter++, order: 11, name: "Agents", link: "/agents", view: "agents/AgentListView", perms: ["agents-list"] },
                { id: _idCounter++, order: 12, name: "Agent info", link: "/agents/view", view: "agents/AgentInfoView", perms: ["agents-list"] },
            ]
        },
        {
            id: _idCounter++, order: 3, type: "middle", name: "Orders", icon: "directions_boat",
            links: [
                { id: _idCounter++, order: 1, name: "SUS List", link: "/orders/sus-list", view: "orders/SusListView", perms: ["sus-data-list"] },
                { id: _idCounter++, order: 2, name: "SUS Exclude", link: "/orders/sus-exclude", view: "orders/SusExcludeView", perms: ["sus-exclude-read"] },
                { id: _idCounter++, order: 3, name: "Cars 2016", link: "/drivers/cars/2016", view: "drivers/CarsData2016View", perms: ["new-cars-2016-read"] },
            ]
        },
        {
            id: _idCounter++, order: 4, type: "middle", name: "LS", icon: "functions",
            links: [
                { id: _idCounter++, order: 1, name: "Oil debt", link: "/ls-debt-oil-driver", view: "ls/LsDebtOilView", perms:["oil-debt-records"] },
                { id: _idCounter++, order: 2, name: "Adv debt", link: "/ls-debt-reklama-list", view: "ls/LsDebtReklamaView", perms:["reklama-debt-records"] },
                { id: _idCounter++, order: 3, name: "Repair debt", link: "/ls-debt-remont-list", view: "ls/LsDebtRemontView", perms:["remont-debt-records"] },

            ]
        },
        {
            id: _idCounter++, order: 5, type: "middle", name: "Files", icon: "folder",
            links: [
                { id: _idCounter++, order: 1, name: "User files", link: "/u" + _idCounter, view: "undefined", perms: ["beta"] },
                { id: _idCounter++, order: 2, name: "Upload", link: "/u" + _idCounter, view: "undefined", perms: ["beta"] },
            ]
        },
        {
            id: _idCounter++, order: 6, type: "middle", name: "Drivers", icon: "directions_car",
            links: [
                { id: _idCounter++, order: 1, name: "Find driver", link: "/u" + _idCounter, view: "undefined", perms: ["beta"] },
            ]
        },
        {
            id: _idCounter++, order: 7, type: "middle", name: "Customers", icon: "group",
            links: [
                { id: _idCounter++, order: 1, name: "Report example", link: "/u" + _idCounter, view: "undefined", perms: ["beta"] },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////
        {
            id: _idCounter++, order: 1, type: "bottom", name: "Notifications", icon: "account_circle",
            links: [
                { id: _idCounter++, order: 5, name: "Info", link: "/u" + _idCounter, view: "undefined"},
                { id: _idCounter++, order: 5, name: "Notifications", link: "/u" + _idCounter, view: "undefined"},
                { id: _idCounter++, order: 6, name: "LogIn", link: "/login", view: "LoginView"},
                { id: _idCounter++, order: 7, name: "LogOut", link: "/logout", view: "LoginView" },
            ]
        }
    ]),

    getCategories: function () {
        if (!useAuth.getCurrentUser()) {
            console.log("useAuth.getCurrentUser() is null");
            this._cats = ref([{
                id: 1, order: 3, type: "middle", name: "Auth", icon: "pets",
                links: [
                    { id: 1, order: 5, name: "LogIn", link: "/login", view: "LoginView" },
                ]
            }]);
        }

        if (this.cats.value.length !== 0) {
            return this.cats.value;
        }

        // exclude categories without links
        this.cats.value = this._cats.value.filter(cat => Array.isArray(cat.links) && cat.links.length > 0);
        // exclude categories without view in link
        this.cats.value = this.cats.value.filter(cat => cat.links.some(link => link.view));

        // filter links only with matched permissions
        this.cats.value.forEach(cat => {
            cat.links = cat.links.filter(link => {
                if (!link.perms) {
                    return true;
                }

                //console.log("link.perms: ", link.perms);
                if (!useAuth.hasPermissions(...link.perms)) {
                    link.view = "forbidden";
                }

                return true;
            });
        });
        return this.cats.value;
    },

    regRoutes: function (router) {
        // const currentUser = useAuth.getCurrentUser();

        const categories = this.getCategories();
        if (categories.length === 0) {
            return;
        }

        categories.forEach(cat => {
            cat.links.forEach(link => {
                if (link.view == "forbidden") {
                    link.link = "/forbidden?perms-required=" + link.perms.join(",");
                }
                router.addRoute({
                    path: link.link,
                    name: link.name,
                    meta: {
                        layout: "Main",
                        auth: true,
                    },
                    component: () => import(`@/views/${link.view}.vue`)
                });
            });
        });

        // set window title by route name
        router.afterEach((to) => {
            let cat = this.getActiveCat();
            if (!cat) {
                return;
            }

            let link = cat.links.find(link => link.link === to.path);
            if (link && link.name) {
                document.title = link.name;
            }
        });

        // set active cat
        this.setActiveCatByLink(stripTrailingSlash(window.location.pathname) || "/");
    },

    getActiveCat: function () {
        return this.getCategories().find(cat => cat.active);
    },

    setActiveCat: function (cat) {
        this.getCategories().forEach(cat => cat.active = false);
        cat.active = true;
    },

    setActiveCatByLink: function (path) {
        let cat = this.getCategories().find(cat => cat.links.some(link => link.link === path));

        if (cat) {
            this.setActiveCat(cat);
            return true;
        }

        return false;
    }
}

const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

export default categories;