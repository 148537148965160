<template>
  <div class="content">
    <div class="middle">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  setup() {
    return {
      msg: "<3",
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 30vh !important;

  background-color: #ccc;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
  //  text-shadow: rgba(255, 255, 255, 0.9) 0px 4px 4px;

  padding-top: 20%;
  opacity: 0.6;
}
</style>
